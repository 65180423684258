
function Button(btn: { text: string, svg: string }) {
    return (
        <div>
            <button type="button"
                className="m-4 bg-orange-500 hover:bg-orange-700 py-2 px-5 border-orange-700 border-2 hover:border-2 rounded-lg text-xl text-white text font-semibold
      focus:ring-4 focus:outline-none focus:ring-orange-300 text-center inline-flex items-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800
      transition duration-150 ease-in-out hover:-translate-y-0 hover:scale-105">
                {btn.text}
                <svg className="ms-1 w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d={btn.svg} clip-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                </svg>
            </button>
        </div>
    )
}
export default Button