import { Link } from 'react-router-dom';
import Button from '../components/Button';

const HomePage = () => {
    const logoUrl = '/powerlogo.png';
    return (
        <div className="App">
            <header className="App-header">
                <img src={logoUrl} className="App-logo" alt="logo" />
                <Link to={'/autotag'}>
                    <Button text='AutoTag' svg='M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z'/>
                </Link>

            </header>
        </div>
    );
}
export default HomePage;