import { Link } from "react-router-dom"
import Button from "../components/Button"


function NotFoundPage() {
    return (
        <div style={{ backgroundColor: "#282c34" }} className="flex items-center justify-center h-screen">
            <div className="text-center">
                <div className="grid grid-cols-3 gap-0">
                    <div>
                        <h1 style={{ fontSize: 200, color: "#ff6600" }} className=" font-semibold mt-6">4</h1>
                    </div>
                    <div>
                        <img src="https://i.kym-cdn.com/entries/icons/facebook/000/048/516/Screenshot_2024-02-20_at_10.43.43_AM.jpg" alt="404"
                            className="w-52 h-52 border-8 border-orange-500 rounded-full mt-20" />
                    </div>
                    <div>
                        <h1 style={{ fontSize: 200, color: "#ff6600" }} className=" font-semibold mt-6">4</h1>
                    </div>
                </div>
                <p className="text-gray-300 m-12 text-5xl">Page not found</p>
                <Link to="/">
                <Button text="Go back home" svg="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5" />                    
                </Link>
            </div>
        </div>
    )
}
export default NotFoundPage
