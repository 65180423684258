import Button from "../components/Button";

function AutoTagPage() {
  const downloadUrl = './downloads/AutoTagSetup.exe';
  const logoUrl = './powerlogo.png';
  return (
    <div className="App">
      <header className="App-header">
        <img src={logoUrl} className="App-logo mb-11" alt="logo" />
        <p className="font-semibold">AutoTag V2.0</p>
        <a href={downloadUrl} download="AutoTagSetup.exe">
          <Button text="Download" svg="M12 19V5m0 14-4-4m4 4 4-4" />
        </a>
      </header>
    </div>
  );
}
export default AutoTagPage;